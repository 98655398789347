import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

import { manifestationIcon } from './manifestations.service';
import { memorialIcon } from './memorial.service';
import { natureIcon } from './nature.service';

export enum HeritageUrls {
  churches = 'crkve-i-manastiri',
  saints = 'slave-i-praznici',
  symbols = 'drzavni-simboli',
  memorials = 'zdanja-i-spomenici',
  nature = 'prirodna-dobra',
  manifestations = 'manifestacije',
}

export enum ChurchType {
  church = 'church',
  monastery = 'monastery',
  other = 'other',
}

export enum ChurchNames {
  church = 'Православне цркве',
  monastery = 'Православни манастири',
  other = 'Остале религије',
}

export enum ChurchPaths {
  church = 'pravoslavne-crkve',
  monastery = 'pravoslavni-manastiri',
  other = 'ostale-religije',
}

export const churchIcon = 'church';
export const churchTypes = [
  {
    id: ChurchType.church,
    name: ChurchNames.church,
    path: ChurchPaths.church,
    icon: churchIcon
  },
  {
    id: ChurchType.monastery,
    name: ChurchNames.monastery,
    path: ChurchPaths.monastery,
    icon: churchIcon
  },
  {
    id: ChurchType.other,
    name: ChurchNames.other,
    path: ChurchPaths.other,
    icon: churchIcon
  },
];

export interface Church {
  id?: string | null;
  name: string | null;
  slug: string | null;
  yearMonth: string | null;
  description: string | null;
  dates: string | null;
  place: string | null;
  imagePath?: string | null;
  type: ChurchType | null;
  founder: string | null;
  founderSlug: string | null;
  created: number | null;
  updated: number | null;
}

export const heritageBoxes = [
  {
    path: HeritageUrls.churches,
    style: 'box-2',
    icon: churchIcon,
    title: 'Духовне светиње',
    image: `url('assets/images/bg-church.jpg')`,
  },
  {
    path: HeritageUrls.nature,
    style: 'box-1',
    icon: natureIcon,
    title: 'Природна добра',
    image: `url('assets/images/bg-nature.jpg')`,
  },
  {
    path: HeritageUrls.memorials,
    style: 'box-6',
    icon: memorialIcon,
    title: 'Здања и споменици',
    image: `url('assets/images/bg-memorial.jpg')`,
  },
  {
    path: HeritageUrls.saints,
    style: 'box-3',
    icon: 'calendar_month',
    title: 'Славе и празници',
    image: `url('assets/images/bg-saint.jpg')`,
  },
  {
    path: HeritageUrls.symbols,
    style: 'box-4',
    icon: 'flag',
    title: 'Државни симболи',
    image: `url('assets/images/bg-state.jpg')`,
  },
  {
    path: HeritageUrls.manifestations,
    style: 'box-5',
    icon: manifestationIcon,
    title: 'Манифестације',
    image: `url('assets/images/bg-manifest.jpg')`,
  },
];

@Injectable({ providedIn: 'root' })
export class ChurchService extends CrudService<Church> {
  override collectionName = 'churches';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}
