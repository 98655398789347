import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export enum ManifestationType {
  culture = 'culture',
  sport = 'sport',
  fair = 'fair',
  tavern = 'tavern',
}

export enum ManifestationPaths {
  culture = 'kultura',
  sport = 'sport',
  fair = 'vasari-i-gastronomija',
  tavern = 'etno-dogadjaji-i-kafane',
}

export enum ManifestationNames {
  culture = 'Културне манифестације',
  sport = 'Спортске манифестације',
  fair = 'Вашари и гастрономија',
  tavern = 'Етно манифестације',
}

export const manifestationIcon = 'stadium';
export const manifestationTypes = [
  {
    'id': ManifestationType.culture,
    'name': ManifestationNames.culture,
    icon: manifestationIcon,
    path: ManifestationPaths.culture,
  },
  {
    'id': ManifestationType.sport,
    'name': ManifestationNames.sport,
    icon: manifestationIcon,
    path: ManifestationPaths.sport,
  },
  {
    'id': ManifestationType.tavern,
    'name': ManifestationNames.tavern,
    icon: manifestationIcon,
    path: ManifestationPaths.tavern,
  },
  {
    'id': ManifestationType.fair,
    'name': ManifestationNames.fair,
    icon: manifestationIcon,
    path: ManifestationPaths.fair,
  },
];

export interface Manifestation {
  id?: string | null;
  name: string | null;
  slug: string | null;
  place: string | null;
  type: ManifestationType | null;
  description: string | null;
  imagePath: string | null;
  externalLink: string | null;
  created: number | null;
  updated: number | null;
}

@Injectable({ providedIn: 'root' })
export class ManifestationService extends CrudService<Manifestation> {
  override collectionName = 'manifestations';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}