import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export enum MemorialType {
  building = 'building',
  museum = 'museum',
  palace = 'palace',
  monument = 'monument',
  location = 'location',
  sports = 'sports',
  streets = 'streets',
}

export enum MemorialPaths {
  building = 'javne-gradjevine',
  museum = 'muzeji',
  palace = 'kuće-i-palate',
  monument = 'spomenici',
  location = 'tvrdjave',
  streets = 'slavne-ulice-i-trgovi',
  sports = 'stadioni-i-hale',
}

export enum MemorialNames {
  building = 'Јавне грађевине',
  museum = 'Културне установе',
  palace = 'Дворци и куће',
  monument = 'Споменици',
  location = 'Тврђаве',
  streets = 'Тргови, мостови, улице',
  sports = 'Спортски објекти',
}

export const memorialIcon = 'account_balance';
export const memorialTypes = [
  {
    'id': MemorialType.building,
    'name': MemorialNames.building,
    icon: memorialIcon,
    path: MemorialPaths.building
  },
  {
    'id': MemorialType.museum,
    'name': MemorialNames.museum,
    icon: memorialIcon,
    path: MemorialPaths.museum
  },
  {
    'id': MemorialType.palace,
    'name': MemorialNames.palace,
    icon: memorialIcon,
    path: MemorialPaths.palace
  },
  {
    'id': MemorialType.monument,
    'name': MemorialNames.monument,
    icon: memorialIcon,
    path: MemorialPaths.monument
  },
  {
    'id': MemorialType.location,
    'name': MemorialNames.location,
    icon: memorialIcon,
    path: MemorialPaths.location
  },
  {
    'id': MemorialType.sports,
    'name': MemorialNames.sports,
    icon: memorialIcon,
    path: MemorialPaths.sports
  },
  {
    'id': MemorialType.streets,
    'name': MemorialNames.streets,
    icon: memorialIcon,
    path: MemorialPaths.streets
  },
];

export interface Memorial {
  id?: string | null;
  name: string | null;
  slug: string | null;
  place: string | null;
  type: MemorialType | null;
  description: string | null;
  imagePath: string | null;
  created: number | null;
  updated: number | null;
}

@Injectable({ providedIn: 'root' })
export class MemorialService extends CrudService<Memorial> {
  override collectionName = 'memorials';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}