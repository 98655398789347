import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export enum NatureType {
  mountain = 'mountain',
  spa = 'spa',
  cave = 'cave',
  park = 'park',
  water = 'water',
  river = 'river',
  etno = 'etno',
  zoo = 'zoo',
}

export enum NatureNames {
  mountain = 'Планине',
  spa = 'Бање',
  cave = 'Пећине',
  park = 'Паркови и резервати',
  water = 'Језера и клисуре',
  river = 'Реке',
  etno = 'Етно села',
  zoo = 'Зоо вртови',
}

export enum NaturePaths {
  mountain = 'planine',
  spa = 'banje',
  cave = 'pecine',
  park = 'parkovi-i-rezervati',
  river = 'reke',
  water = 'jezera-kanjoni-i-klisure',
  etno = 'etno-sela',
  zoo = 'zoo-vrtovi',
}

export const natureIcon = 'forest';
export const natureTypes = [
  {
    id: NatureType.mountain,
    name: NatureNames.mountain,
    path: NaturePaths.mountain,
    icon: natureIcon
  },
  {
    id: NatureType.spa,
    name: NatureNames.spa,
    path: NaturePaths.spa,
    icon: natureIcon
  },
  {
    id: NatureType.cave,
    name: NatureNames.cave,
    path: NaturePaths.cave,
    icon: natureIcon
  },
  {
    id: NatureType.park,
    name: NatureNames.park,
    path: NaturePaths.park,
    icon: natureIcon
  },
  {
    id: NatureType.water,
    name: NatureNames.water,
    path: NaturePaths.water,
    icon: natureIcon
  },
  {
    id: NatureType.river,
    name: NatureNames.river,
    path: NaturePaths.river,
    icon: natureIcon
  },
  {
    id: NatureType.etno,
    name: NatureNames.etno,
    path: NaturePaths.etno,
    icon: natureIcon
  },
  {
    id: NatureType.zoo,
    name: NatureNames.zoo,
    path: NaturePaths.zoo,
    icon: natureIcon
  },
];

export interface Nature {
  id?: string | null;
  name: string | null;
  slug: string | null;
  place: string | null;
  type: NatureType | null;
  description: string | null;
  imagePath: string | null;
  created: number | null;
  updated: number | null;
}

@Injectable({ providedIn: 'root' })
export class NatureService extends CrudService<Nature> {
  override collectionName = 'nature';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}